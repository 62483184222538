







































import Vue from 'vue'
import EditionListings from './EditionListings.vue'
import EditionSelect from './EditionSelect.vue'
import IEdition from '../models/IEdition'

export default Vue.extend({
  components: {
    EditionListings,
    EditionSelect
  },
  props: {
    edition: Object as () => IEdition
  },
  data() {
    return {
      replacementEditionId: 0
    }
  },
  computed: {
    replacementEdition(): IEdition {
      return this.$store.getters['Editions/getEditionById'](
        this.replacementEditionId
      )
    }
  },
  methods: {
    editionFilter(edition: IEdition) {
      return this.edition.id !== edition.id
    },
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('submit', this.replacementEditionId)
    }
  }
})
