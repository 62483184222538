<template>
  <div>
    <EditionsTable />
  </div>
</template>

<script>
import EditionsTable from '../components/EditionsTable.vue'

export default {
  components: {
    EditionsTable
  }
}
</script>
