import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c('h2',[_vm._v("Editions")])]),_c(VCol,{staticClass:"shrink"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.activeItem = {}}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("$add")]),_vm._v("Add ")],1)],1)],1),_c('ODataTable',{ref:"table",staticClass:"manage-editions",attrs:{"url":"Editions?$select=id,uniqueId,displayName,description,comments","headers":_vm.headers,"expanded":_vm.expanded,"sort-by":['displayName'],"single-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.onRowClick,"item-expanded":_vm.onItemExpanded},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c(VBtn,{attrs:{"small":"","icon":"","title":"Edit"},on:{"click":function($event){$event.stopPropagation();_vm.activeItem = _vm.$clone(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$edit")])],1),_c(VBtn,{attrs:{"small":"","icon":"","title":"Delete"},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$trash")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length}},[_c('h4',[_vm._v("Listings")]),_c('EditionListings',{attrs:{"edition":_vm.edition}})],1)]}}],null,true)}),_c('EditionDialog',{on:{"cancel":function($event){_vm.activeItem = null},"submit":_vm.onSave},model:{value:(_vm.activeItem),callback:function ($$v) {_vm.activeItem=$$v},expression:"activeItem"}}),_c('DeleteEditionDialog',{attrs:{"edition":_vm.deleteItem},on:{"cancel":function($event){_vm.deleteItem = null},"submit":_vm.onDeleteConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }