
































































import Vue from 'vue'
import EditionDialog from './EditionDialog.vue'
import EditionListings from './EditionListings.vue'
import DeleteEditionDialog from './DeleteEditionDialog.vue'
import IODataTableHeader from '@/models/IODataTableHeader'
import ODataTable from '@/components/ODataTable.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('Editions')

export default Vue.extend({
  components: {
    ODataTable,
    EditionDialog,
    DeleteEditionDialog,
    EditionListings
  },
  data() {
    return {
      activeItem: null,
      deleteItem: null,
      searchText: null,
      edition: null,
      expanded: []
    }
  },
  computed: {
    deleteMessage(): string {
      const name = this.deleteItem && this.deleteItem.displayName
      return `Are you sure you want to delete edition '${name}'?`
    },
    headers(): IODataTableHeader[] {
      return [
        {
          text: 'Name',
          value: 'displayName',
          width: '30%',
          searchable: true
        },
        {
          text: 'Description',
          value: 'description',
          width: '30%',
          searchable: true
        },
        {
          text: 'Comments',
          value: 'comments',
          width: '30%',
          searchable: true
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 1
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getEditionById: 'getEditionById',
      deleteEdition: 'deleteEdition',
      reassignEdition: 'reassignEdition',
      saveEdition: 'saveEdition'
    }),
    async refresh() {
      const table = this.$refs.table as any
      await table.getItems()
    },
    async onSave(item) {
      await this.saveEdition(item)
      this.activeItem = null
      this.refresh()
    },
    async onDelete(item) {
      this.deleteItem = await this.getEditionById(item.id)
    },
    async onDeleteConfirm(replacementEditionId) {
      if (replacementEditionId) {
        await this.reassignEdition({
          edition: this.deleteItem,
          replacementEditionId
        })
      } else {
        await this.deleteEdition(this.deleteItem)
      }
      this.deleteItem = null
      this.refresh()
    },
    onRowClick(item, tableEvent) {
      tableEvent.expand(!tableEvent.isExpanded)
    },
    async onItemExpanded({ item, value }) {
      if (value) {
        this.edition = await this.getEditionById(item.id)
      }
    }
  }
})
